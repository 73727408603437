<template>
  <v-app>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-card color="#020240" dark width="600px" elevation="5">
          <v-card-title>
            <img style="margin: auto" id="logo" src="../../assets/logo.png" width="300px" />
          </v-card-title>
          <v-card-text class="text-h6 mt-4 white--text">
            <v-row align="center">
              <v-col cols="12">
                Select an account
                <v-progress-linear
                  color="light-blue darken-2"
                  indeterminate
                  rounded
                  height="6"
                  v-if="isLoading"
                ></v-progress-linear>
                <v-select
                  v-model="selectedAccount"
                  :items="accounts"
                  item-text="name"
                  item-value="name"
                  dense
                  @change="go"
                ></v-select>
              </v-col>
              <!-- <v-col cols="2">
                <v-btn
                  color="success"
                  dark
                  @click.stop="go()"
                  :disabled="!selectedAccount"
                  >GO</v-btn
                >
              </v-col> -->
            </v-row>
            <div style="text-align: center">
              Or
            </div>
            <v-row align="center">
              <v-col cols="9">
                  Create a new one
                  <v-form ref="form" v-model="valid" @keydown.enter="$event.preventDefault()" lazy-validation>
                  <v-text-field
                    label="Account name"
                    v-model="name"
                    :rules="nameRules"
                  ></v-text-field>
                </v-form>
              </v-col>
              <v-col cols="3">
                  <v-btn color="success" :disabled="!valid" dark @click="submit">
                    <v-icon > mdi-plus </v-icon> Create</v-btn
                  >
              </v-col>
            </v-row>
            <div>
              <p v-if="AccountNameErrors.length">
                <b class="errorsList">Please correct the following error(s):</b>
                <ul>
                  <li class="errorsList" v-for="error in AccountNameErrors" :key="error">{{ error }}</li>
                </ul>
              </p>
            </div>
            <v-divider class="my-5"></v-divider>
            <v-row class="devider">
              <v-col cols="12">
                <v-btn color="error" @click="logout">
                  <v-icon>mdi-logout</v-icon><span>Logout</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { defineComponent } from "vue-demi";
import useAccountStore from "../../stores/accountStore";
import useAuthStore from "../../stores/authStore";

export default defineComponent({
  name: "Lobby",
  data() {
    return {
      selectedAccount: null,
      debug: "",
      accounts: [],
      name: null,
      nameRules: [(v) => !!v || "Account name is required"],
      valid: true,
      isLoading: false,
      maxAttempts:3,
      AccountNameErrors: [],
    };
  },
  setup() {
    const accountStore = useAccountStore();
    const authStore = useAuthStore();
    return {
      accountStore: accountStore,
      authStore: authStore,
    };
  },
  async mounted() {
    this.isLoading = true;
    
    //!- Retry design-pattern to ensure auth information are charged before token retrieval (Loby page load) 
    const retry = async (attempt) => {
      if(this.authStore.account != null){
        await this.accountStore.fetchAccounts();
        this.accounts = this.accountStore.accounts;
        this.isLoading = false;
      }
      else{
        await this.authStore.getAccounts();
        //!- Retry strategy
        if (attempt <= this.maxAttempts) {
          //!- One more second every time
          const nextAttempt = attempt + 1;
          const delayInSeconds = Math.max(Math.min(nextAttempt, 60), 1);
          setTimeout(() => retry(nextAttempt), delayInSeconds * 1000);
        } else {
          this.isLoading = false;
          this.$emit("error", "Authentication timeout!");
        }
      } 
    }
    retry(0);
  },

  methods: {
    go() {
      this.$router.push(`/${this.selectedAccount}/audits`);
    },
    async submit() {
      const formIsValid = this.$refs.form.validate();
      if (!formIsValid) return;
      
      this.AccountNameErrors = [];
      if (this.name != this.name.toLowerCase()){
        this.AccountNameErrors.push("Account name must only contain lowercase");
      }
      if (this.name != this.name.replace(/ /g, "")){
        this.AccountNameErrors.push("Account name must not contain spaces");
      }
      if (this.AccountNameErrors.length){
        return;
      }
      this.selectedAccount = this.name;
      const response = await this.accountStore.createAccount(this.name);

      if (response.ok) {
        this.isLoading = true;
        await this.accountStore.fetchAccounts();
        this.accounts = this.accountStore.accounts;
        this.$refs.form.reset();
        this.isLoading = false;
        this.go();
      }
    },
    logout() {
      this.authStore.logout();
    },
  },
});
</script>
<style>
.errorsList{
  color: red;
  font-size: small;
}
</style>